<template>
  <div class="login-page">
    <div v-if="$system.user.isAuthenticated" class="container">
      <div v-if="loading" class="container">
        <div class="row flex-center mt-5">
          <b-spinner variant="dark"></b-spinner>
        </div>
      </div>
      <div v-else class="container">
        <div class="row flex-center mt-5 text-center">
          <h1>
            Hi, you are logged as
            <template v-if="$system.user.LastName">
              {{ $system.user.LastName }}
            </template>
            <template v-if="$system.user.FirstName">
              {{ $system.user.FirstName }}
            </template>
          </h1>
        </div>
      </div>
    </div>
    <div v-else class="container">
      <div class="row flex-center mt-5">
        <b-spinner variant="dark"></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import './LoginAdminPage.scss'
import AuthService from '@/services/auth/auth.service'
export default {
  name: 'LoginPage',
  metaInfo: {
    title: 'Login as Viewer',
    titleTemplate: '%s | ISO Portal | Nexi ',
  },
  data() {
    return {
      loading: true,
      user: {
        Email: this.$route.query.email,
        Password: this.$route.query.hash,
        Type: 'viewer',
      },
    }
  },
  mounted() {
    if (this.$system.user.isAuthenticated) {
      this.$router.push('/')
    } else if (
      this.user.Email &&
      this.user.Password &&
      this.user.Type &&
      !this.$system.user.isAuthenticated
    ) {
      this.loginUser()
    }
  },

  methods: {
    async loginUser() {
      try {
        if (this.user.Password && this.user.Email) {
          const response = await AuthService.authUserView(this.user)
          if (
            response &&
            response.status == 'success' &&
            response.body &&
            response.password
          ) {
            this.user.Password = response.password
            this.$system.login(
              this.user,
              response.body.Id,
              response.body.FirstName,
              response.body.LastName
            )
            this.loading = false
            this.$system.makeToast(
              'success',
              'Authorization',
              'Authorization successfull!',
            )
          } else {
            this.$router.push('/')
            this.$system.makeToast(
              'error',
              'Authorization',
              'Invalid Authorization!',
            )
          }
        }
      } catch (err) {
        this.$system.makeToast(
          'error',
          'Authorization',
          'Invalid Authorization!',
        )
        this.$router.push('/')
      }
    },
  },
}
</script>
