import Axios from '../axios-instance/axios.service';

const API_URL =
  process.env.NODE_ENV !== 'development' ? `${window.location.origin}/api/` : 'http://localhost:5001/api/';

class AuthService {
  async authUser(user) {
    const { data } = await Axios.post(`${API_URL}login`, user);
    return data;
  }
  async authUserView(user) {
    const { data } = await Axios.post(`${API_URL}loginadmin`, user);
    return data;
  }
  async logoutUser(user) {
    const { data } = await Axios.post(`${API_URL}logout`, user);
    return data;
  }
  async resetPassword(email) {
    const { data } = await Axios.post(`${API_URL}reset`, email);
    return data;
  }
}

export default new AuthService();